import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import LoginView from "@/views/LoginView.vue";
import CpanelLayout from "@/layouts/CpanelLayout.vue";
import PromptsView from "@/views/PromptsView.vue";
import UsersView from "@/views/UsersView.vue";
import ErrorsView from "@/views/ErrorsView.vue";
import ResumesView from "@/views/ResumesView.vue";
import SupportView from "@/views/SupportView.vue";
import ResumesPreview from '@/views/ResumesPreview.vue';
import ReportsView from '@/views/ReportsView.vue';
import AuthTracking from "@/views/AuthTracking.vue";
import RedditView from '@/views/RedditView.vue';

const routes = [
  {
    path: '/',
    redirect: '/cpanel',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/cpanel',
    component: CpanelLayout,
    children: [
      {
        path: '',
        name: 'users',
        component: UsersView
      },
      {
        path: 'prompts',
        name: 'prompts',
        component: PromptsView
      },
      {
        path: 'auth-tracking',
        name: 'auth-tracking',
        component: AuthTracking
      },
      {
        path: 'errors',
        name: 'errors',
        component: ErrorsView
      },
      {
        path: 'resumes',
        name: 'resumes',
        component: ResumesView
      },
      {
        path: 'support',
        name: 'support',
        component: SupportView
      },
      {
        path: 'resumes-preview',
        name: 'resumes-preview',
        component: ResumesPreview
      },
      {
        path: 'reports',
        name: 'reports',
        component: ReportsView
      },
      {
        path: 'reddit',
        name: 'reddit',
        component: RedditView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
