import axios from "axios"
import {api} from "./api"
import router from "@/router";

export const state = {
  user: {
    originalFileName: '',
    templateCV: ''
  },
  trackingData: {
    users: [],
    stats: {
      signUp: 0,
      signIn: 0,
      success: 0,
      fail: 0,
      total: 0
    }
  },
  filters: {
    today: false
  },
  usersCopy: [],
  fetching: false
}

export const mutations = {
  SET_AUTH (state, {key, data}) {
    state[key] = data
  },
  SET_FETCHING (state, val) {
    state.fetching = val
  },
  SET_TEMPLATE (state, template) {
    state.user.templateCV = template
  },
  FILTER_AUTH_TODAY (state) {
    state.filters.today = !state.filters.today;
    if (state.filters.today) {
      state.usersCopy = state.trackingData.users.slice();
      state.trackingData.users = state.trackingData.users
        .filter(user => new Date(user.modifiedAt).getDate() === new Date().getDate())
    } else {
      state.trackingData.users = state.usersCopy.slice();
      state.usersCopy = [];
    }
  },
  SET_TRACKING_DATA (state, data) {
    state.trackingData.users = data.users;
    // state.trackingData.users = data.users.map(o => ({...o, events: o.events.reverse()}));
    state.trackingData.stats.total = data.users.length;
    data.users.forEach(user => {
      if (user.events.some(event => event.action?.includes('signIn'))) {
        state.trackingData.stats.signIn += 1;
      }
      if (user.events.some(event => event.action?.includes('signUp'))) {
        state.trackingData.stats.signUp += 1;
      }
      if (user.events.some(event => event.tags?.includes('success'))) {
        state.trackingData.stats.success += 1;
      }
      if (user.events.every(event => !event.tags?.includes('success'))) {
        state.trackingData.stats.fail += 1;
      }
    });
  },
  RESET_STATS () {
    state.trackingData.stats = {
      signUp: 0,
      signIn: 0,
      success: 0,
      fail: 0,
      total: 0
    }
  }
}

export const actions = {
  async login ({commit}, user) {
    try {
      const url = `${api.auth}/login`
      const { data } = await axios.post(url, user)
      commit('SET_AUTH', {key: 'user', data})
      await router.push('/cpanel')
    } catch (err) {
      console.log(err.response)
    }
  },
  async check_login ({commit}) {
    try {
      const url = `${api.auth}/login`
      const { data } = await axios.get(url)
      commit('SET_AUTH', {key: 'user', data})
    } catch (err) {
      console.log(err.response)
      await router.push('/login')
    }
  },
  async get_auth_tracking ({commit}) {
    try {
      commit('SET_FETCHING', true);
      commit('RESET_STATS');
      const url = `${api.tracking}/auth`;
      const {data} = await axios.get(url);
      commit('SET_TRACKING_DATA', data);
      commit('SET_FETCHING', false);
    } catch (err) {
      console.log(err.response || err)
    }
  },
}