<template>
  <div class="reddit-dashboard">
    <RedditFilter
      :initial-status="filters.status"
      :initial-promoting="filters.promoting"
      @update:filters="updateFilters"
    />
    
    <div class="reddit-stats">
      <h4>Stats</h4>
      <div class="stats-text">
        <p>
          Total promoting posts commented in past 7 days =
          {{ totalPromotingPosts }}
        </p>
        <p>
          Total non-promoting posts commented in past 7 days =
          {{ totalNonPromotingComments }}
        </p>
        <p>
          % promoting posts live after days (posted in past 7 days) =
          {{ percentPromotingPosts }}%
        </p>
        <p>
          % non-promoting posts live after days (posted in past 7 days) =
          {{ percentNonPromotingPosts }}%
        </p>
      </div>
    </div>

    <div class="comments-list">
      <div
        v-for="(group, date) in groupedFilteredComments"
        :key="date"
        class="comment-group"
      >
        <div class="comment-date">{{ date }}</div>
        <div v-for="comment in group" :key="comment.id" class="comment-item">
          <div class="user-info">USER: {{ comment.name }}</div>
          <div class="comment-details">
            <div>Comment published = {{ comment.createdAt }}</div>
            <div>Type of comment = {{ comment.type }}</div>
            <div>
              Comment url =
              <a target="_blank" :href="comment.commentUrl">{{
                comment.commentUrl ? 'Click here' : 'N/A'
              }}</a>
            </div>
            <div>
              Post url = <a target="_blank" :href="comment.url">{{ comment.url ? 'Click here' : 'N/A' }}</a>
            </div>
            <div class="status-line">
              <span>Status after 3 days = </span>
              <span
                class="status-dot"
                :class="{
                  'status-green': comment.exists === 'active',
                  'status-red': comment.exists === 'deleted',
                  'status-gray': comment.exists === 'pending',
                }"
              ></span>
            </div>
            <div class="likes-line">
              Likes after 3 days = {{ comment.exists === 'deleted' ? 'Comment deleted' : (comment.likes || 0) }}
            </div>
          </div>
          <div class="comment-section">
            <p class="comment-text">Comment = {{ comment.comment }}</p>
            <details>
              <summary class="post-expandable">Post</summary>
              <p class="post-content">
                {{ comment.postText }}
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import RedditFilter from './RedditFilter.vue';

const props = defineProps({
  totalPromotingPosts: Number,
  totalNonPromotingComments: Number,
  percentPromotingPosts: Number,
  percentNonPromotingPosts: Number,
  comments: Array,
  posts: Array,
});

const filters = ref({
  status: null,
  promoting: null
});

const updateFilters = (newFilters) => {
  filters.value = newFilters;
};

const filteredPosts = computed(() => {
  return props.posts.filter(post => {
    const statusMatch = 
      !filters.value.status || 
      (filters.value.status === 'deleted' ? post.exists === 'deleted' : post.exists !== 'deleted');
      
    const promotingMatch = 
      !filters.value.promoting || 
      (filters.value.promoting === 'promoting' ? post.type === 'promoting' : post.type !== 'promoting');
    
    return statusMatch && promotingMatch;
  });
});

const groupedFilteredComments = computed(() => {
  return filteredPosts.value.reduce((groups, comment) => {
    const date = comment.createdAt;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(comment);
    return groups;
  }, {});
});
</script>

<style lang="scss" scoped>
@import "../styles/vars.scss";

.reddit-dashboard {
  .reddit-stats {
    margin-bottom: 30px;

    h4 {
      margin: 0;
      font-weight: bold;
    }

    .stats-text {
      p {
        margin: 10px 0px;
      }
    }
  }

  .comments-list {
    .comment-group {
      margin-bottom: 20px;

      .comment-date {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .comment-item {
        margin-bottom: 10px;

        .user-info {
          margin-bottom: 5px;
        }

        .comment-details {
          div {
            margin: 3px 0;
          }

          .status-line {
            display: flex;
            align-items: center;
            gap: 5px;

            .status-dot {
              width: 17px;
              height: 17px;
              border-radius: 50%;
              display: inline-block;

              &.status-green {
                background-color: $green;
              }

              &.status-red {
                background-color: $red;
              }
              &.status-gray {
                background-color: $light-gray;
              }
            }
          }
        }

        .comment-section {
          margin: 10px 0px;

          .post-expandable {
            cursor: pointer;
            margin: 10px 0px;
          }
          .comment-text,
          .post-content {
            max-width: 50%;
          }
        }
      }
    }
  }
}
</style>