<template>
  <main class="auth-tracking flex">
    <div class="left-side">
      <h1 class="text-center">
        Auth Tracking
      </h1>

      <div class="text-center hide">
        <input type="search" placeholder="search email" />
      </div>

      <div class="main-content">
        <p class="stats">
        <span class="capitalize"
              v-for="(value, key) in data.stats"
              :key="key">
          {{ key }}: {{ value }} •
        </span>
        </p>

        <ul class="users-list">
          <li v-for="({_id, browser, os, events}, index) in data.users"
              :key="index">
            <h3 :class="handleStatusClass(events)">
              &nbsp;{{ handleEmail(events) }} {{ _id }}&nbsp;
            </h3>
            <span class="browser-details">
             {{ browser }}, {{ os }}
          </span>


            <ul class="user-events m-t-8">
              <li class="flex"
                  v-for="({createdAt, action, context, notes}, index) in events"
                  :key="index">
              <span class="date-hour">
                {{ createdAt && format(new Date(createdAt), 'dd MMM. -> HH:mm:ss') }}&nbsp;&nbsp;
              </span>
                <span class="details">
                <span>
                  • &nbsp;{{ action }} ({{ context }}) <br>
                </span>
                <span v-if="notes">
                  - {{ notes }}
                </span>
              </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="right-side">
      <label for="today" class="pointer">
        <input type="checkbox"
               id="today"
               @change="handleFilter('today')"/>
        today only
      </label>
      <br>
      <button class="m-t-24"
              :disabled="fetching"
              @click="handleFilter('refresh')">
        {{ fetching ? 'loading...' : 'Refresh' }}
      </button>
    </div>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";
  import { format } from 'date-fns'

  const {state, dispatch, commit} = useStore();
  dispatch('get_auth_tracking');
  const data = computed(() => state.auth.trackingData);
  const fetching = computed(() => state.auth.fetching);

  function handleStatusClass (events) {
    return events.some((o) => o.tags?.includes('success'))
        ? 'success-session'
        : 'fail-session'
  }

  function handleEmail (events) {
    return events.reduce((acc, curr) => {
      !acc && !curr.email?.id && (acc = curr.email);
      return acc;
    }, '')
  }

  function handleFilter (filter) {
    switch (filter) {
      case 'today':
        commit('FILTER_AUTH_TODAY')
        break
      case 'refresh':
        dispatch('get_auth_tracking');
        break
    }
  }
</script>

<style lang="scss">
  .auth-tracking {
    @media only screen and (min-width: 0) {
      h1 {
        margin-top: 0;
      }
      b {
        font-weight: bold;
      }
      details {
        cursor: pointer;
      }
      .main-content {
        max-width: 1000px;
        margin: 0 auto;
        h3 {
          margin-bottom: 8px;
          font-size: 1.8rem;
          font-weight: 500;
          position: relative;
          &:before {
            border-radius: 50%;
          }
          &.success-session {
            &:before {
              content: '✅';
            }
          }
          &.fail-session {
            &:before {
              content: '❌';
            }
          }
          &.linkedin-outline {
            color: #304eb1;
          }
        }
        .stats {
          text-align: right;
          span {
            margin-left: auto;
            font-size: 1.8rem;
          }
        }
        .browser-details {
          margin-left: 12px;
          opacity: 0.7;
        }
        .users-list {
          li {
            margin-bottom: 40px;
          }
        }
        .user-events {
          padding: 12px;
          border: 1px solid lightgrey;
          border-radius: 4px;
          li {
            margin-bottom: 12px;
            .date-hour {
              font-size: 1.8rem;
              flex: 0 0 auto;
              opacity: 0.6;
            }
            .details span {
              font-size: 1.8rem;
            }
          }
        }
      }

      .right-side {
        width: 200px;
        height: 100%;
        padding: 40px 0;
        position: fixed;
        right: 0;
        top: 0;
        background-color: white;
        border-left: 1px solid lightgrey;
        text-align: center;
      }
    }
  }
</style>