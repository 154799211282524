<template>
  <div class="reddit-messages">
    <div v-for="(messageGroup, date) in groupedMessagesByDate" :key="date">
      <h4 class="reddit-messages__date">{{ formatDate(date) }}</h4>
      <div
        v-for="message in messageGroup"
        :key="message.timestamp"
        class="reddit-messages__item"
      >
        <div>USER: {{ message.username }}</div>
        <div>Message timestamp = {{ formatTime(message.createdAt) }}</div>
        <details>
          <summary>Message:</summary>
          <p class="body-message">{{ message.body }}</p>
        </details>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { format } from "date-fns";

const props = defineProps({
  messages: Array
});

const groupedMessagesByDate = computed(() => {
  return props.messages.reduce((groups, message) => {
    const date = new Date(message.createdAt).toISOString().split("T")[0];

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});
});

const formatDate = (date) => {
  return format(new Date(date), "dd MMMM yyyy");
};


const formatTime = (timestamp) => {
  return format(new Date(timestamp), "dd MMMM yyyy HH:mm");
};
</script>

<style lang="scss" scoped>
@import "../styles/vars.scss";

.reddit-messages {
  &__date {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__item {
    margin-bottom: 20px;

    div {
      margin-bottom: 3px;
    }
  }

  .body-message {
    max-width: 50%;
  }
}
</style>