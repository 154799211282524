<template>
  <h1>Reddit View</h1>
  <div v-if="loading">
      <BaseLoader />
    </div>
  <div v-else class="reddit-view">
    <div class="tabs">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        :class="{ active: currentTab === tab.id }"
        @click="currentTab = tab.id"
      >
        {{ tab.name }}
      </button>
    </div>
    <div class="tab-content">
      <div v-if="currentTab === 'comments'" class="comments-posted-container">
        <RedditComments
          :totalPromotingPosts="123"
          :totalNonPromotingComments="123"
          :percentPromotingPosts="123"
          :percentNonPromotingPosts="123"
          :posts="posts"
        />
      </div>
      <div v-if="currentTab === 'messages'" class="messages-recieved-container">
        <RedditMessages  :messages="messages" />
      </div>
      <div v-if="currentTab === 'settings'" class="settings-container">
        <RedditSettings :accounts="accounts" />
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import RedditComments from "@/components/RedditComments.vue";
import RedditMessages from "@/components/RedditMessages.vue";
import RedditSettings from "@/components/RedditSettings.vue";
import BaseLoader from "@/components/BaseLoader.vue";

const currentTab = ref("comments");

const { state, dispatch, commit } = useStore();
const posts = computed(() => state.reddit.posts);
const messages = computed(() => state.reddit.messages);
const loading = computed(() => state.reddit.loading);
const accounts = computed(() => state.reddit.accounts);


onMounted(() => {
  dispatch("get_redditPosts");
  dispatch("get_redditMessages");
  dispatch("get_redditSettingsAccounts");
});

const tabs = [
  { id: "comments", name: "Comments posted" },
  { id: "messages", name: "Messages received" },
  { id: "settings", name: "Settings" },
];

</script>
  
<style lang="scss" scoped>
@import "../styles/vars.scss";
.reddit-view {
  .tabs {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
    button {
      padding: 8px 16px;
      border: none;
      background-color: $light-kaki;
      cursor: pointer;
      border-radius: 4px;
      &.active {
        background-color: $kaki;
        color: $black;
      }
    }
  }
  .comments-posted-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}
</style>