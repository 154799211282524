<template>
  <div class="reddit-settings">
    <!-- Number of accounts -->
    <div>
      <h4 class="font-bold">
        Number of accounts being utilized = {{ accounts.length }}
      </h4>
    </div>

    <!-- Accounts section -->
    <div>
      <h4 class="font-bold">Accounts</h4>
      <div v-for="account in accounts" :key="account._id" class="account-item">
        <span>{{ account._id }}</span>
        <span
          class="status-dot"
          :class="account.isPaused ? 'green-status' : 'red-status'"
        ></span>
        <button @click="toggleAccountStatus(account)" class="toggle-button">
          {{
            account.isPaused
              ? "Click here to pause this account"
              : "Click here to enable this account"
          }}
        </button>
      </div>
    </div>

    <!-- Blacklist section -->
    <div class="blacklist-section">
      <h4 class="font-bold">Blacklist subreddit group</h4>
      <div class="input-group">
        <label>Enter url of subreddit to ban =</label>
        <input
          v-model="subredditUrl"
          type="text"
          placeholder="reddit.com/r/group"
        />
      </div>

      <div>
        <p>Select for which user to ban it :</p>
        <div>
          <div
            v-for="account in accounts"
            :key="account._id"
            class="checkbox-item"
          >
            <input
              type="checkbox"
              :id="account._id"
              v-model="selectedBans"
              :value="account._id"
            />
            <label :for="account._id">{{ account._id }}</label>
          </div>
          <div class="ban-all-container">
            <input
              type="checkbox"
              id="banAll"
              v-model="banAll"
              class="checkbox-input"
            />
            <label class="ban-all-label" for="banAll"
              >Ban from all accounts</label
            >
          </div>
        </div>
      </div>

      <button class="save-button" :disabled="!subredditUrl.trim()" @click="saveBans">Save</button>
    </div>
  </div>
</template>
  
  <script setup>
const props = defineProps({
  accounts: Array,
});

import { ref, watch } from "vue";
import { useStore } from "vuex";


const { state, dispatch, commit } = useStore();
const subredditUrl = ref("");
const selectedBans = ref([]);
const banAll = ref(false);

const toggleAccountStatus = async (account) => {
  await dispatch("update_accountStatus", {
    accountId: account._id,
    isPaused: !account.isPaused,
  });
  account.isPaused = !account.isPaused
};

watch(banAll, (newValue) => {
  if (newValue) {
    selectedBans.value = props.accounts.map((account) => account._id);
  } else {
    selectedBans.value = [];
  }
});

const saveBans = async () => {
  await dispatch('post_Blacklist', {
    url: subredditUrl.value,
    bannedAccounts: selectedBans.value
  })
  subredditUrl.value = ""
  selectedBans.value = []
  banAll.value = false
}
</script>
  
  <style lang="scss" scoped>
@import "../styles/vars.scss";

.reddit-settings {
  .blacklist-section {
    .input-group {
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        border-radius: 4px;
        border: 1px solid $gray;
        text-align: center;
        padding: 0px 30px;
      }
    }
    .checkbox-item {
      margin-bottom: 5px;
      font-size: 14px;
      color: $gray;
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        appearance: none;
        -webkit-appearance: none;
        background-color: $white;
        border: 1px solid $gray;
        border-radius: 3px;
        cursor: pointer;
        position: relative;

        &:checked {
          background-color: $white;
          border: 1px solid $gray;

          &::after {
            content: "X";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $black;
            font-size: 14px;
          }
        }
      }
    }
    .ban-all-container {
      display: flex;
      align-items: center;
      .ban-all-label {
        color: $gray;
      }
    }
    input[type="checkbox"]#banAll {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      appearance: none;
      -webkit-appearance: none;
      background-color: $white;
      border: 1px solid $gray;
      border-radius: 3px;
      cursor: pointer;
      position: relative;

      &:checked {
        background-color: $white;
        border: 1px solid $gray;

        &::after {
          content: "X";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $black;
          font-size: 14px;
        }
      }
    }
    .save-button {
      margin-top: 30px;
      background: $kaki;
    }
    .checkbox-input {
      height: 20px;
      width: 20px;
    }
  }
  .font-bold {
    font-weight: bold;
  }
  h4 {
    margin: 20px 0px 10px 0px;
  }
  .account-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
    .status-dot {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: inline-block;
    }
    .green-status {
      background-color: $green;
    }
    .red-status {
      background-color: $red;
    }
    .toggle-button {
      background-color: $light-gray;
      padding: 7px 8px;
      font-size: 14px;
    }
  }
}
</style>