import axios from "axios"
import { api } from "./api"
import { format } from 'date-fns'
import { enGB } from 'date-fns/locale'

export const state = {
  posts: [],
  messages: [],
  loading: false,
  accounts: []
}

export const mutations = {
  SET_STATE(state, { key, data }) {
    state[key] = data
  },
  SET_LOADING (state, value) {
    state.loading = value
  }
}

export const actions = {
  async get_redditPosts({ commit }) {
    try {
      commit('SET_LOADING', true)
      const url = `${api.reddit}/posts`
      const { data } = await axios.get(url)
      const formattedData = data.map(post => ({
        ...post,
        createdAt: post.createdAt ? format(new Date(post.createdAt), 'dd MMMM yyyy', {
          locale: enGB 
        }) : ''
      }))
  
      commit('SET_STATE', { key: 'posts', data: formattedData })
      commit('SET_LOADING', false)
    } catch (err) {
      commit('SET_LOADING', false)
      console.log(err)
    }
  },
  async get_redditMessages({ commit }) {
    try {
      const url = `${api.reddit}/messages`
      const { data } = await axios.get(url)
      const formattedData = data.map(message => ({
        ...message,
        createdAt: message.createdAt ? format(new Date(message.createdAt), 'dd MMMM yyyy HH:mm', {
          locale: enGB
        }) : ''
      }))
  
      commit('SET_STATE', { key: 'messages', data: formattedData })
    } catch (err) {
      console.log(err)
    }
  },
  async get_redditSettingsAccounts({ commit }) {
    try {
      const url = `${api.reddit}/accounts`
      const { data } = await axios.get(url)
      commit('SET_STATE', { key: 'accounts', data: data })
    } catch (err) {
      console.log(err)
    }
  },
  async update_accountStatus({ commit }, { accountId, isPaused }) {
    try {
      const url = `${api.reddit}/accounts/${accountId}`
      await axios.put(url, { isPaused })
    } catch (err) {
      console.log(err)
    }
  },
  async post_Blacklist({ commit }, { url, bannedAccounts }) {
    try {
      const blacklistUrl = `${api.reddit}/blacklist`
      await axios.post(blacklistUrl, {
        url,
        bannedAccounts
      })
    } catch (err) {
      console.log(err)
    }
  },
}