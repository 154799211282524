export const api = {
  auth: '/api/auth',
  tracking: '/api/tracking',
  users: '/api/cpanel/users',
  sandbox: '/api/cpanel/sandbox',
  resumes: '/api/cpanel/resumes',
  resume: '/resume',
  generatedResume: '/generated-resume',
  errors: '/api/cpanel/errors',
  support: '/api/cpanel/support/tickets',
  reports: '/api/cpanel/reports',
  reddit: '/api/cpanel/reddit'
}