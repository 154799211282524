<template>
  <div class="filters-container">
    <div class="filter-group">
      <div class="filter-header" @click="toggleStatus">
        <h4>Status filter</h4>
        <div class="arrow" :class="{ 'arrow-up': showStatus }"></div>
      </div>
      <div class="filter-options" v-show="showStatus">
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedStatus === null">X</span>
          </div>
          <input
            type="radio"
            name="status"
            :value="null"
            v-model="selectedStatus"
            class="hidden-radio"
          />
          Show all
        </label>
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedStatus === 'deleted'">X</span>
          </div>
          <input
            type="radio"
            name="status"
            value="deleted"
            v-model="selectedStatus"
            class="hidden-radio"
          />
          Only show comments removed
        </label>
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedStatus === 'active'">X</span>
          </div>
          <input
            type="radio"
            name="status"
            value="active"
            v-model="selectedStatus"
            class="hidden-radio"
          />
          Only show comments not removed
        </label>
      </div>
    </div>

    <div class="filter-group">
      <div class="filter-header" @click="togglePromoting">
        <h4>Promoting filter</h4>
        <div class="arrow" :class="{ 'arrow-up': showPromoting }"></div>
      </div>
      <div class="filter-options" v-show="showPromoting">
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedPromoting === null">X</span>
          </div>
          <input
            type="radio"
            name="promoting"
            :value="null"
            v-model="selectedPromoting"
            class="hidden-radio"
          />
          Show all
        </label>
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedPromoting === 'promoting'"
              >X</span
            >
          </div>
          <input
            type="radio"
            name="promoting"
            value="promoting"
            v-model="selectedPromoting"
            class="hidden-radio"
          />
          Only show promoting comments
        </label>
        <label>
          <div class="checkbox">
            <span class="x-mark" v-show="selectedPromoting === 'not-promoting'"
              >X</span
            >
          </div>
          <input
            type="radio"
            name="promoting"
            value="not-promoting"
            v-model="selectedPromoting"
            class="hidden-radio"
          />
          Only show not-promoting comments
        </label>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, watch } from "vue";

const props = defineProps({
  initialStatus: String,
  initialPromoting: String,
});

const emit = defineEmits(["update:filters"]);

const selectedStatus = ref(props.initialStatus);
const selectedPromoting = ref(props.initialPromoting);
const showStatus = ref(true);
const showPromoting = ref(true);

const toggleStatus = () => {
  showStatus.value = !showStatus.value;
};

const togglePromoting = () => {
  showPromoting.value = !showPromoting.value;
};

watch([selectedStatus, selectedPromoting], ([status, promoting]) => {
  emit("update:filters", {
    status,
    promoting,
  });
});
</script>
  
  <style lang="scss" scoped>
@import "../styles/vars.scss";

.filters-container {
  margin-bottom: 20px;
  display: flex;

  .filter-group {
    width: 300px;
    &:not(:last-child) {
      margin-right: 40px;
    }

    .filter-header {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;

      h4 {
        margin: 0;
        font-weight: bold;
      }

      .arrow {
        position: relative;
        width: 20px;
        height: 20px;
        margin-left: 4px;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          width: 10px;
          height: 10px;
          border-right: 2px solid $gray;
          border-bottom: 2px solid $gray;
          transform: translate(-50%, -75%) rotate(45deg);
          transition: transform 0.2s ease;
        }

        &.arrow-up::after {
          transform: translate(-50%, -25%) rotate(-135deg);
        }
      }
    }

    .filter-options {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $gray;
        cursor: pointer;
      }

      .checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid $gray;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .x-mark {
        color: $black;
        font-size: 14px;
      }

      .hidden-radio {
        display: none;
      }
    }
  }
}
</style>